import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import LogoLandscape from '../../assets/images/logo-landscape-white.png'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { ENABLE_PIGEON_IN_HOUSE } from '../../config'
import {
  Users,
  Inbox,
  ShoppingCart,
  Folder,
  PlusCircle,
  Activity,
  Globe,
  Archive,
  Gift,
  Tool,
  Database,
  Home,
} from 'react-feather'
import CreateNewAssigmentModal from '../CreateNewAssigment/index'
import NewJudicialBrief from '../judicialBriefs/NewJudicialBrief'
import { Button } from 'reactstrap'
import Clock from '../common/Clock'

const Sidebar = () => {
  const settings = useSelector((state) => state.settings)
  const auth = useSelector((state) => state.auth)

  const [collapsed, setCollapsed] = useState({})
  const { activeUser } = auth

  useEffect(() => {
    // Load initial collapse states from local storage
    let sidebar
    try {
      sidebar = JSON.parse(localStorage.getItem('sidebar')) || {}
    } catch (_error) {
      sidebar = {}
    }

    setCollapsed(sidebar)
  }, [])

  const toggleCollapse = (id) => {
    const isCollapsed = !collapsed[id]
    setCollapsed((prev) => ({ ...prev, [id]: isCollapsed }))

    // Update localStorage after state change
    localStorage.setItem(
      'sidebar',
      JSON.stringify({ ...collapsed, [id]: isCollapsed })
    )
  }

  const roleLinks = {
    admin: adminLinks,
    client: activeUser.is_substitute
      ? substituteLinks
      : activeUser.is_procurador
      ? procuradorLinks
      : clientLinks,
    company: companyLinks,
    partner: partnerLinks,
  }

  const activeLinks = roleLinks[activeUser.role]

  return (
    <nav
      className={`sidebar sidebar-stick ${!settings.isOpen ? 'toggled' : ''}`}
      style={{ minHeight: '100%' }}
    >
      <div className="sidebar-content">
        <PerfectScrollbar>
          <NavLink className="sidebar-brand pt-3" to="/panel">
            <img
              className="align-middle mt-1"
              src={LogoLandscape}
              alt=""
              width="160"
            />
          </NavLink>

          <div className="sidebar-user text-center">
            <Clock />
          </div>

          {activeUser.role !== 'admin' && (
            <div className="mt-2">
              <CreateNewAssigmentModal activeUser={activeUser} />
            </div>
          )}

          <ul className="sidebar-nav mt-2">
            {activeLinks &&
              activeLinks.map((activeLink) => {
                return activeLink ? (
                  <SidebarLink
                    key={activeLink.label}
                    {...activeLink}
                    collapsed={collapsed}
                    toggleCollapse={toggleCollapse}
                  />
                ) : null
              })}
          </ul>
        </PerfectScrollbar>
      </div>
    </nav>
  )
}

export default Sidebar

const AdminNewHearing = () => (
  <NavLink to="/substitute-list">
    <Button
      type="button"
      size="lg"
      color="outline-secondary"
      className="m-1 border-0"
    >
      <PlusCircle size={16} className="align-middle" />
    </Button>
  </NavLink>
)

// prettier-ignore
const commonLinks = [
  { path: '/community', label: 'Partners', icon: Globe, color: 'red' },
  { path: '#', label: 'Herramientas', icon: Tool, id: 'tools', children: [ { path: '/panel/calculators', label: '🔢 Calculadora intereses' }]},
]

// prettier-ignore
const commonCompanyPartnerLinks = [
  { path: '#', label: 'Encargos', icon: Folder, id: 'expedients',
    children: [
      { path: '/panel/expedients', label: '👩‍⚖️ Vistas' },
      { path: '/panel/expedients?expedient_type=notary', label: 'ᝰ.ᐟ Notaría'},
      { path: '/panel/projects-short-term', label: '✍️ On call' },
      ENABLE_PIGEON_IN_HOUSE === 'true' && { path: '/panel/pigeon-inhouse', label: '🏢 In house'} ,
      { path: '/panel/procura', label: '👨‍⚖️ Procura' },
    ].filter(Boolean),
  },
]

// prettier-ignore
const pigeonInHouseLink = ENABLE_PIGEON_IN_HOUSE === 'true' 
  ? [{path: '/panel/pigeon-inhouse?status=awaiting_assignation&show=all', label: 'Pigeon In House', icon: Home, id: 'PigeonInHouse'}] 
  : []

// prettier-ignore
const adminLinks = [
  ...pigeonInHouseLink,
  { path: '/panel', label: 'Dashboard', icon: Activity, id: 'dashboard', },
  { path: '#', label: 'Encargos', icon: Folder, id: 'expedients',
    children: [
      { path: '/panel/expedients', label: '👩‍⚖️ Vistas', hasExtra:true, extraComponent: <AdminNewHearing />},
      { path: '/panel/expedients?expedient_type=notary', label: 'ᝰ.ᐟ Notaría' },
      { path: '/panel/projects-short-term', label: '✍️On call' },
      { path: '/panel/pigeon-inhouse', label: '🏢 In house'},
      { path: '/panel/procura', label: '👨‍⚖️  Procura' },
      { path: '/panel/drafts', label: '📥 Borradores' },
    ],
  },
  { path: '#', label: 'Escritos Judiciales', icon: Folder, id: 'jucidial-briefs',
    children: [
      { path: '/panel/judicila-briefs', label: 'Escritos', hasExtra:true, extraStyle:{ paddingRight: '100px' }, extraComponent: <NewJudicialBrief type="icon" />},
    ],
  },
  { path: '/panel/users', label: 'Usuarios', icon: Users, id: 'users' },
  { path: '#', label: 'Facturas', icon: Folder, id: 'facturas',
    children: [
      { path: '/panel/invoices?type=income_company&sort=created_at', label: '📈 Company'},
      { path: '/panel/invoices?type=income&sort=created_at', label: '📉 Clientes' },
      { path: '/panel/invoices?type=expense', label: '🏦 Sustitutos' },
      { path: '/panel/remittances', label: '📬 Remesas' },
    ],
  },
  ...commonLinks,
  { path: '/panel/promotions', label: 'Promociones', icon: Gift },
  { path: '/panel/dataset', label: 'Dataset', icon: Database}
]

// prettier-ignore
const partnerLinks = [
  ...pigeonInHouseLink,
  ...commonCompanyPartnerLinks,
  ...commonLinks
]

// prettier-ignore
const companyLinks = [
  ...pigeonInHouseLink,
  ...commonCompanyPartnerLinks,
  { path: '/panel/invoices?type=income_company', label: 'Facturas', icon: Archive },
  ...commonLinks
]

// prettier-ignore
const clientLinks = [
  ...pigeonInHouseLink,
  {
    path: '#', label: 'Encargos', icon: Folder, id: 'expedients',
    children: [
      { path: '/panel/expedients', label: '👩‍⚖️ Vistas' },
      { path: '/panel/projects-short-term', label: '✍️ On call' },
      ENABLE_PIGEON_IN_HOUSE === 'true' && { path: '/panel/pigeon-inhouse', label: '🏢 In house'} ,
      { path: '/panel/procura', label: '👨‍⚖️ Procura' },
    ].filter(Boolean),
  },
  { path: '/panel/invoices?type=income', label: 'Facturas', icon: Archive },
  ...commonLinks
]

// prettier-ignore
const substituteLinks = [
  ...pigeonInHouseLink,
  {
    path: '#', label: 'Encargos asignados', icon: Inbox, id: 'expedients',
    children: [
      { path: '/panel/assigned-expedients', label: '📅 Vistas' },
      { path: '/panel/assigned-projects-short-term', label: '🖊️ On call' },
      ENABLE_PIGEON_IN_HOUSE === 'true' && { path: '/panel/assigned-pigeon-inhouse', label: '🏢 In house'},
    ].filter(Boolean),
  },
  {
    path: '#', label: 'Encargos contratados', icon: ShoppingCart, id: 'expedients',
    children: [
      { path: '/panel/expedients', label: '👩‍⚖️ Vistas' },
      { path: '/panel/projects-short-term', label: '✍️ On call' },
      ENABLE_PIGEON_IN_HOUSE === 'true' && { path: '/panel/pigeon-inhouse', label: '🏢 In house'},
      { path: '/panel/procura', label: '👨‍⚖️ Procura' },
    ].filter(Boolean),
  },
  { path: '#', label: 'Facturas', icon: Folder, id: 'facturas',
    children: [
      { path: '/panel/invoices?type=income&sort=created_at', label: '📄 Facturas compras' },
      { path: '/panel/invoices?type=expense', label: '💼 Facturas sustituciones' },
    ],
  },
  ...commonLinks
]

// prettier-ignore
const procuradorLinks = [
  {
    path: '#', label: 'Encargos asignados', icon: Inbox, id: 'expedients',
    children: [
      { path: '/panel/assigned-procuras', label: '📅 Vistas procura' },
    ],
  },
  {
    path: '#', label: 'Encargos contratados', icon: ShoppingCart, id: 'expedients',
    children: [
      { path: '/panel/expedients', label: '👩‍⚖️ Vistas' },
      { path: '/panel/projects-short-term', label: '✍️ On call' },
      ENABLE_PIGEON_IN_HOUSE === 'true' && { path: '/panel/pigeon-inhouse', label: '🏢 In house'},
      { path: '/panel/procura', label: '👨‍⚖️ Procura' },
    ].filter(Boolean),
  },
  { path: '#', label: 'Facturas', icon: Folder, id: 'facturas',
    children: [
      { path: '/panel/invoices?type=income&sort=created_at', label: '📄 Facturas compras' },
      { path: '/panel/invoices?type=expense',  label: '💼 Facturas sustituciones' },
    ],
  },
  ...commonLinks
]

const SidebarLink = ({
  path,
  label,
  hasExtra,
  icon: Icon,
  children,
  toggleCollapse,
  collapsed,
  extraComponent,
}) => (
  <>
    {!children ? (
      <NavLink to={path} className={`sidebar-link`}>
        <Icon size={18} className="align-middle me-3" />
        {label}
      </NavLink>
    ) : (
      <span
        data-toggle="collapse"
        className={`sidebar-link ${collapsed[label] ? '' : 'collapsed'}`}
        aria-expanded={collapsed[label]}
        id={label}
        onClick={() => toggleCollapse(label)}
      >
        <Icon size={18} className="align-middle me-3" />
        {label}
      </span>
    )}
    {children && (
      <div
        className={`collapse ${collapsed[label] ? 'show' : ''}`}
        id={`collapse${label}`}
      >
        <ul className="sidebar-dropdown list-unstyled">
          {children.map((child) => (
            <li
              key={child.label}
              className={`sidebar-item ${
                child.hasExtra
                  ? 'sidebar-item d-flex justify-content-between'
                  : ''
              }`}
              style={child.hasExtra ? { background: '#313b4c' } : {}}
            >
              <span
                className={`${
                  child.hasExtra
                    ? 'sidebar-item d-flex justify-content-between'
                    : ''
                }`}
                style={child.hasExtra ? { background: '#313b4c' } : {}}
              >
                <NavLink
                  to={child.path}
                  className="sidebar-link"
                  style={child.extraStyle}
                >
                  {child.icon && (
                    <child.icon size={18} className="align-middle me-3" />
                  )}
                  {child.label}
                </NavLink>
              </span>
              {child.extraComponent}
            </li>
          ))}
        </ul>
        {extraComponent}
      </div>
    )}
  </>
)
