import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Form, FormGroup, Label, Input, InputGroup } from 'reactstrap'
import { login } from '../../redux/actions/auth'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { PasswordEye } from '../PasswordEye'

const LoginForm = ({ showAwsCognitoInfo }) => {
  const [showPassword, setShowPassword] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const handleLogin = (e) => {
    e.preventDefault()
    dispatch(login({ email, password }))
    navigate('/panel')
  }

  const handleOnHover = () => {
    setShowPassword(!showPassword)
  }

  return (
    <Form onSubmit={handleLogin}>
      <FormGroup>
        <Label>Email</Label>
        <Input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          bsSize="lg"
          type="email"
          placeholder="Email"
        />
      </FormGroup>
      <FormGroup>
        <Label>Contraseña</Label>
        <InputGroup className="text-bottom">
          <Input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            bsSize="lg"
            type={!showPassword ? 'password' : 'text'}
            placeholder="Contraseña"
            style={{ borderRight: 'none' }}
          />
          <PasswordEye
            showPassword={showPassword}
            handleOnHover={handleOnHover}
          />
        </InputGroup>
        {showAwsCognitoInfo && (
          <small>
            <Link to="/forgot-password">¿Has olvidado la contraseña?</Link>
          </small>
        )}
      </FormGroup>
      <div className="d-flex justify-content-between">
        <Button color="primary" className="w-100">
          Entrar
        </Button>
      </div>

      <div className="mt-5">
        <small>
          ¿Aún no tienes cuenta? Regístrate gratis ahora como
          <Link
            to={`/client-signup${location.search}`}
            style={{ color: 'var(--secondary)' }}
          >
            {' '}
            cliente{' '}
          </Link>{' '}
          o
          <Link
            to={`/substitute-signup${location.search}`}
            style={{ color: 'var(--secondary)' }}
          >
            {' '}
            sustituto{' '}
          </Link>
        </small>
      </div>
    </Form>
  )
}

export default LoginForm
