import { useEffect } from 'react'
import { Button, CardBody } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const ThanksSubstitute = () => {
  const navigate = useNavigate()
  const auth = useSelector((state) => state.auth)

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/create-user-form/name')
    }
  }, [navigate])

  const handleButtonClick = () => {
    navigate(
      auth.activeUser.is_procurador
        ? '/panel/assigned-procuras'
        : '/panel/assigned-expedients'
    )
  }

  return (
    <CardBody>
      <div className="mt-5 mb-3 d-flex justify-content-center align-items-center flex-column">
        <h2 style={{ lineHeight: '1.5' }} className="col-md-6 text-center">
          Gracias por registrarte. Nuestro equipo validará tu solicitud.
        </h2>
        <h2 style={{ lineHeight: '1.5' }} className="col-md-6 text-center">
          Con tu usuario también podrás solicitar una sustitución cuando lo
          necesites.
        </h2>
      </div>
      <div className="d-flex flex-md-row flex-column justify-content-md-center align-items-md-center">
        <Button onClick={handleButtonClick} color="primary" size="lg">
          Ir a la aplicación
        </Button>
      </div>
    </CardBody>
  )
}

export default ThanksSubstitute
