import * as types from '../constants'
import { toast } from 'react-toastify'
import { googleLogout } from '../../services/socialLogin'
// import  mixActions  from '../../mixPanelsActions'

const defaultState = {
  activeUser: {},
}

export const auth = (state = defaultState, action) => {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      localStorage.setItem('token', action.payload.token)
      localStorage.setItem('loginType', action.payload.loginType || null)
      // const { _id, name, last_name, email, is_substitute = false, role, created_at } = action.payload.user

      // mixActions.identify(_id);
      // mixActions.track('logIn')
      // mixActions.people.set({
      //   $first_name: name,
      //   $last_name: last_name,
      //   $email: email,
      //   $created_at: created_at,
      //   $role: role,
      //   $is_substitute: is_substitute
      // })

      return {
        ...state,
        activeUser: { ...action.payload.user, rules: action.payload.rules },
      }

    case types.LOGIN_ERROR:
      let message = 'Contraseña incorrecta'
      if (action.payload.status === 401) {
        message =
          'Hemos mejorado la seguridad de su cuenta. Por favor, resetee la contraseña.'
      }
      if (action.payload.status === 404) {
        message = 'El usuario no existe'
      }

      if (action.payload.status === 403) {
        toast.warn(action.payload.response.message, { theme: 'colored' })
        return state
      }

      toast.error(message, { theme: 'colored' })
      setTimeout(() => {
        googleLogout()
      }, 2000)
      return state

    case types.LOGIN_FORM_SUCCESS:
      localStorage.setItem('token', action.payload.token)
      return {
        ...state,
        activeUser: { ...action.payload.user, rules: action.payload.rules },
      }

    case types.LOGIN_FORM_ERROR:
      toast.error(
        'El usuario ya existe, registrate para contratar el encargo',
        { theme: 'colored' }
      )
      return state

    case types.SESSION_SUCCESS:
      // mixActions.identify(action.payload.user._id)
      // mixActions.track('session')
      // mixActions.people.set({
      //   $first_name: action.payload.user.name,
      //   $last_name: action.payload.user.last_name,
      //   $email: action.payload.user.email,
      //   $created_at: action.payload.user.created_at,
      //   $role: action.payload.user.role,
      //   $is_substitute: action.payload.user.is_substitute
      // })
      localStorage.setItem('token', action.payload.token)

      return {
        ...state,
        activeUser: {
          ...action.payload.user,
          rules: action.payload.rules,
          rule: action.payload.rule,
        },
      }

    case types.SESSION_ERROR:
      localStorage.removeItem('token')
      setTimeout(() => {
        googleLogout()
      }, 2000)
      window.location.reload()
      return defaultState

    case types.LOGOUT_SUCCESS:
      localStorage.clear()
      return {
        ...state,
        activeUser: {},
      }

    case types.CREATE_CLIENT_SUCCESS:
      toast.success('Usuario registrado correctamente', { theme: 'colored' })
      localStorage.setItem('token', action.payload.token)
      return {
        ...state,
        activeUser: action.payload.user,
      }

    case types.UPDATE_USER_SUCCESS:
      if (action.payload.user._id === state.activeUser._id) {
        return {
          ...state,
          activeUser: action.payload.user,
        }
      }

      return state

    case types.CHANGE_EMAIL_REQ_SUCCESS:
      toast.success(
        'Cambio de email autorizado. Recibirá el código en su email',
        { theme: 'colored' }
      )
      return state
    case types.CHANGE_EMAIL_REQ_ERROR:
      toast.error(
        'Cambio de email no autorizado. Contacte con el administrador',
        { theme: 'colored' }
      )
      return state

    case types.CHANGE_EMAIL_SUCCESS:
      toast.success(
        'Cambio de email realizado. Cierre sesión y acceda con el nuevo email',
        { theme: 'colored' }
      )

      localStorage.clear()
      return {
        ...state,
        activeUser: {},
      }

    case types.CHANGE_EMAIL_ERROR:
      toast.error(
        'Cambio de email no realizado. Contacte con el administrador',
        { theme: 'colored' }
      )
      return state
    default:
      return state
  }
}
