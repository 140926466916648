import React, { useState, useEffect } from 'react'

const Clock = () => {
  const [hora, setHora] = useState(new Date())

  useEffect(() => {
    const intervalo = setInterval(() => {
      setHora(new Date())
    }, 1000)

    // Limpiar el intervalo al desmontar el componente
    return () => clearInterval(intervalo)
  }, [])

  const formatoHora = hora.toLocaleTimeString()

  return <h3 style={{ color: 'white' }}>{formatoHora}</h3>
}

export default Clock
