import React, { useState, useEffect, useCallback } from 'react'
import AsyncSelect from 'react-select/async'
import { baseAPI } from '../../config'

const UserSearch = ({
  className,
  onSubmit,
  defaultValue = null,
  isDisabled = false,
  isMulti = false,
  type,
  company,
}) => {
  const [userRole, setUserRole] = useState(type || null)

  useEffect(() => {
    setUserRole(type || null)
  }, [type])

  const loadUsers = useCallback(
    async (inputValue) => {
      const url = `${baseAPI}/users/users-search?q=${inputValue}&userRole=${userRole}&company=${company}`

      const options = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }

      try {
        const response = await fetch(url, options)
        if (!response.ok) {
          return []
        }
        const data = await response.json()

        return data.map((i) => ({
          label: `${i.name} ${i.last_name} - ${i.email}`,
          value: i._id,
          name: i.name,
          last_name: i.last_name,
          email: i.email,
          invoice_data: i.invoice_data,
          use_custom_fields: i.use_custom_fields,
          custom_fields: i.custom_fields,
          partner_parent: i.partner_parent,
          role: i.role,
          invoice_city: i.invoice_city,
        }))
      } catch (_error) {
        return []
      }
    },
    [userRole, company]
  )

  return (
    <div className={className}>
      <AsyncSelect
        cacheOptions={true}
        defaultOptions
        onChange={onSubmit}
        defaultValue={defaultValue}
        loadOptions={loadUsers}
        isDisabled={isDisabled}
        isMulti={isMulti}
      />
    </div>
  )
}

export default UserSearch
