import * as types from '../constants'
import { toast } from 'react-toastify'

const defaultState = {
  procuras: {},
  created: {},
  procura: {},
  assigned: {},
  drafts: {},
}

export const procura = (state = defaultState, action) => {
  let msg = ''

  switch (action.type) {
    case types.LOAD_PROCURAS_SUCCESS:
      return {
        ...state,
        procuras: { ...action.payload },
      }

    case types.LOAD_PROCURA_SUCCESS:
      return {
        ...state,
        procura: { ...action.payload.procura },
      }

    case types.CLEAR_PROCURA:
      return {
        ...state,
        procura: {},
      }

    case types.CREATE_PROCURA_SUCCESS:
      toast.success('Encargo creado', { theme: 'colored' })
      return {
        ...state,
        created: { ...action.payload.procura },
      }

    case types.CREATE_PROCURA_ERROR:
      if (action.payload.status === 503) {
        toast.error(
          'Ahora mismo no tenemos colaboradores disponibles. Contacte con el administrador.',
          { theme: 'colored' }
        )
      } else {
        toast.error(
          'Ha ocurrido un error, si persiste contacte con el administrador',
          { theme: 'colored' }
        )
      }
      return state

    case types.RESET_CREATED_PROCURA:
      return {
        ...state,
        created: {},
      }

    case types.UPDATE_PROCURA_SUCCESS:
      toast.success('Expediente actualizado', { theme: 'colored' })
      return {
        ...state,
        procura: {
          ...state.procura,
          procura: {
            ...state.procura.procura,
          },
        },
      }

    case types.UPDATE_PROCURA_ERROR:
      msg = 'Ha ocurrido un error actualizando la vista'
      if (action.payload.status === 401)
        msg = 'No tienes permisos para actualizar la vista'
      toast.error(msg, { theme: 'colored' })
      return { ...state }

    case types.LOAD_ASSIGNED_PROCURA_SUCCESS:
      return {
        ...state,
        assigned: { ...action.payload },
      }

    case types.UPDATE_PROCURA_REPORT_SUCCESS:
      toast.success('Informe guardado', { theme: 'colored' })
      return {
        ...state,
        procura: {
          ...state.procura,
          procura: {
            ...state.procura.procura,
          },
        },
      }

    case types.UPDATE_PROCURA_REPORT_ERROR:
      toast.error('Error guardando el informe')
      return { ...state }

    case types.LOAD_PROCURA_DRAFTS_SUCCESS:
      return {
        ...state,
        drafts: { ...action.payload },
      }

    case types.PROCURA_DELETED_ERROR:
      msg = 'Ha ocurrido un error eliminando el encargo'
      if (action.payload.status === 401)
        msg = 'No tienes permisos para eliminar el encargo'
      toast.error(msg, { theme: 'colored' })
      return { ...state }

    case types.PROCURA_DELETED_SUCCESS:
      toast.success('Expediente cancelado/eliminado correctamente', {
        theme: 'colored',
      })
      return { ...state }

    case types.PROCURA_DOC_REMINDER_SUCCESS:
      toast.success('Recordatorio enviado', { theme: 'colored' })
      return {
        ...state,
        procura: { ...action.payload.procura },
      }

    case types.PROCURA_DOC_REMINDER_ERROR:
      toast.error(
        action.payload.response.message ||
          'Ha ocurrido un error enviando el recordatorio',
        { theme: 'colored' }
      )
      return { ...state }

    case types.REASSING_PROCURA_SUCCESS:
      toast.success('Encargo reasignado', { theme: 'colored' })
      return {
        ...state,
        procura: { ...action.payload.procura },
      }

    case types.REASSING_PROCURA_ERROR:
      toast.error('Ha ocurrido un error al reasignar el encargo', {
        theme: 'colored',
      })
      return { ...state }

    case types.PROCURA_NOTIFICATION_SUCCESS:
      return { ...state }

    case types.PROCURA_NOTIFICATION_ERROR:
      return { ...state }

    case types.PROCURA_DELETE_FILE_SUCCESS:
      toast.success('Documento eliminado correctamente', { theme: 'colored' })
      return {
        ...state,
        procura: { ...action.payload.procura },
      }

    case types.PROCURA_DELETE_FILE_ERROR:
      toast.error('No se ha podido eliminar el documento', { theme: 'colored' })
      return { ...state }

    case types.URGENT_ISSUE_REMINDER_PROCURA_SUCCESS:
      toast.success('Se han mandado los emails correctamente', {
        theme: 'colored',
      })
      return {
        ...state,
        procura: {
          ...state.procura,
          ...action.payload.document,
        },
      }

    case types.URGENT_ISSUE_REMINDER_PROCURA_ERROR:
      toast.error('Ha ocurrido algun error', { theme: 'colored' })
      return { ...state }

    default:
      return state
  }
}
