import React, { useState, useEffect } from 'react'
import PageLayout from '../layouts/Page'
import { useDispatch } from 'react-redux'
import { createClient, createClientIDP } from '../redux/actions/users'
import { Card, CardBody, Col, Row, Container } from 'reactstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Loading from '../components/common/Loading'
import GoogleButton from 'react-google-button'
import { toast } from 'react-toastify'
import { googleSignUp } from '../services/socialLogin'
import { AWS_REDIRECT_URI_SIGNUP, ENABLE_SOCIAL_LOGIN } from '../config'
import pigeonsLetters from '../assets/images/37.png'

const ClientSignUpPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [searchParams, setSearchParams] = useState({})
  const [redirectToThanks, setRedirectToThanks] = useState(false)

  const [formValues, setFormValues] = useState({
    email: '',
    name: '',
    last_name: '',
    phone: '',
    password: '',
  })

  useEffect(() => {
    const query = new URLSearchParams(location.search.replace('?', ''))
    let sessionUtmParams = sessionStorage.getItem('utmParams')
    if (query && (query.utm_source || query.utm_campaign || query.utm_medium)) {
      const utmParams = `utm_source=${query.utm_source}&utm_medium=${query.utm_medium}&utm_campaign=${query.utm_campaign}`
      if (!sessionUtmParams) sessionStorage.setItem('utmParams', utmParams)
      sessionUtmParams = sessionStorage.getItem('utmParams')
      setSearchParams({
        utm_source: query.utm_source || '',
        utm_medium: query.utm_medium || '',
        utm_campaign: query.utm_campaign || '',
      })
    }

    if (query.state && query.code && !query.error) {
      setLoading(true)
      const code_verifier = sessionStorage.getItem(
        `code_verifier-${query.state}`
      )
      sessionStorage.removeItem(`code_verifier-${query.state}`)
      if (code_verifier === null) {
        throw new Error('Unexpected code')
      }

      dispatch(
        createClientIDP({
          code: query.code,
          code_verifier,
          redirect_uri: `${AWS_REDIRECT_URI_SIGNUP}`,
          query: sessionUtmParams,
        })
      )
        .then(() => {
          setLoading(false)
          setRedirectToThanks(true)
        })
        .catch(() => {
          setLoading(false)
        })
      sessionStorage.removeItem('utmParams')
    }

    if (query.repeat && !query.error) {
      setLoading(true)
      googleSignUp()
      setLoading(false)
    }

    if (query.error) {
      if (query.error_description?.startsWith('Already found')) {
        navigate(`/client-signup?repeat=true`)
      } else {
        toast.error('Ha ocurrido un error, contacte con el administrador', {
          theme: 'colored',
        })
        navigate(`/client-signup`)
      }
    }
  }, [location.search, navigate, dispatch])

  useEffect(() => {
    if (redirectToThanks) {
      navigate('/thanks-client')
    }
  }, [redirectToThanks, navigate])

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormValues({
      ...formValues,
      [name]: value,
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)
    const user = { ...formValues, role: 'client' }
    user['utm_source'] = searchParams.utm_source
    user['utm_medium'] = searchParams.utm_medium
    user['utm_campaign'] = searchParams.utm_campaign
    try {
      const { payload } = await dispatch(createClient({ user }))
      if (payload.token) {
        setRedirectToThanks(true)
      }
    } catch (error) {
      toast.error('Error al registrar el usuario', { theme: 'colored' })
    } finally {
      setLoading(false)
    }
  }

  const showGoogle = ENABLE_SOCIAL_LOGIN

  return (
    <PageLayout className="text-center">
      <Container fluid className="vh-100 d-flex">
        <Row className="w-100">
          <Col
            md={6}
            className="d-flex flex-column align-items-center justify-content-center p-4"
          >
            <Loading loading={loading} />

            <div className="text-center mt-4">
              <h1 className="h2">
                <span role="img" aria-label="rocket">
                  Empieza Hoy con Legal Pigeon 🚀
                </span>
              </h1>
              <p className="lead">
                Únete ahora y mejora la optimización de tus procesos legales en
                minutos.
              </p>
            </div>

            <Card
              className="w-100"
              style={{ maxWidth: '500px', margin: 'auto' }}
            >
              <CardBody>
                {showGoogle && (
                  <>
                    <div className="d-flex justify-content-around mt-3">
                      <GoogleButton
                        type="light"
                        onClick={googleSignUp}
                        label="Registrate con Google"
                      />
                    </div>
                    <div className="text-center bold mt-4 mb-4">
                      o rellena el formulario
                    </div>
                  </>
                )}
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      name="email"
                      className="form-control"
                      value={formValues.email}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Nombre</label>
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      value={formValues.name}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Apellidos</label>
                    <input
                      type="text"
                      name="last_name"
                      className="form-control"
                      value={formValues.last_name}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Teléfono</label>
                    <input
                      type="text"
                      name="phone"
                      className="form-control"
                      value={formValues.phone}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="form-group">
                    <label>Contraseña</label>
                    <input
                      type="password"
                      name="password"
                      className="form-control"
                      value={formValues.password}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <button type="submit" className="btn btn-primary w-100">
                    Registrarse
                  </button>
                </form>
                <div className="mt-5">
                  <small>
                    Ya tienes una cuenta en Legal Pigeon?{' '}
                    <Link to="/login">Inicia sesión</Link>
                  </small>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col
            md={6}
            className="d-none d-md-flex align-items-center justify-content-center p-0"
          >
            <img
              src={pigeonsLetters}
              alt="Legal Pigeon"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </Col>
        </Row>
      </Container>
    </PageLayout>
  )
}

export default ClientSignUpPage
